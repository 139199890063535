<script>
//import DatePicker from "vue2-datepicker";
import Layout from "../../layouts/main";
// import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useHttp from '@/comp-functions/useHttp'
import {formatCurrency, formatNumber,formatDateTimeToDate,formatDateTimeToTime} from '@/utils/formatter'
import { ref, onMounted } from '@vue/composition-api'
import router from "@/router"
//import i18n from "../../../i18n"

const DETAIL_ORDER = {
  oddGoodstypecode: 'GDTGNC', 
  oddGoodsTypeDescr: 'General Cargo',
  oddPackingtypecode: 'PACKRG', 
  oddPackingstypedesc: 'Karung',
  oddCollies: 3, 
  oddWeightPerColly: 15,
  oddWidth: 20, 
  oddHeight: 30, 
  oddLength: 40, 
  oddVolumePerColly: 0,
  oddChargeweightactual: 0,
  oddChargeweightround: 0,
  oddFreightcharge:0,
  oddSurchargepct:0,
  oddSurchargeamount:0,
  oddHandlingcharge:0,
  oddTotalcharge:0,
  oddRemarks: 'Remarkszz', 
  //oddChargearr:[]
  }

export default {
  page: {
    title: "Create Booking Project Order",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    //DatePicker,
    // Multiselect,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Create Booking Order",
      items: [],
      select_label: "Select",
      checkedtnc : false
    };
  },
  
  setup(){
    const { $get } = useHttp()
    const { $post } = useHttp()
    const selectedSchedule = ref({})
    const inputedSchedule = ref({})
    const configDropship = ref({})
    const orderRules = ref({})
    const form = ref({
      ordScheduleId: 1,//props.schedule_id,
      ordCollies: 0,
      ordWeightactual: 0,
      ordWeightcharge: 0,
      ordWeight: 0,
      ordFreightcharge:0,
      ordSurcharge:0,
      ordHandlingcharge:0,
      ordHandlingdgcharge:0,
      ordAdminfee:0,
      ordDiscountpct:0,
      ordDiscountamount:0,
      ordVatcharge:0,
      ordGtotalcharge:0,
      ordShipperName: '',
      ordShipperAddress: '',
      ordShipperPhone: '',
      ordConsigneeName: '',
      ordConsigneeAddress: '',
      ordConsigneePhone: '',
      details: [],
      ordercharges: []
    })


    const currentDetailOrder = ref({...DETAIL_ORDER})



    const getVendorconfig = async () => {
      const {data} = await $get({ url: 'master/vendorconfigall/'+selectedSchedule.value.sch_vendorid+'/'+selectedSchedule.value.origin_port_id+'/'+selectedSchedule.value.sch_aircrafttypecode})
      orderRules.value = data.orderrule.vnc_configs.goods_validation
      configDropship.value = data.dropship.vnc_configs.dropship
    }

    const postForm = async () => {
      const schedulePost =({
        schVendorId: inputedSchedule.value.sch_vendor.id,
        schAircraftTypeCode: inputedSchedule.value.sch_aircrafttype.lbc_code,
        schDepartureDatetime: formatDateTimeToDate(inputedSchedule.value.departure_datetime)+' '+formatDateTimeToTime(inputedSchedule.value.departure_datetime),
        schArrivalDatetime: formatDateTimeToDate(inputedSchedule.value.arrival_datetime)+' '+formatDateTimeToTime(inputedSchedule.value.arrival_datetime),
        schFlightNo: inputedSchedule.value.sch_flight_no,
        schOriginPortId: inputedSchedule.value.origin_port.prt_id,
        schDestinationPortId: inputedSchedule.value.destination_port.prt_id,
        schDuration: inputedSchedule.value.sch_duration,
        schTransit: inputedSchedule.value.sch_number_of_transit,
        schPriceKg: inputedSchedule.value.sch_tariff_per_kg,
        schCapacity: inputedSchedule.value.sch_availablecapacity,
        schIsActive: true,
        
      })
      const dataPost = ({order: form.value,schedule: schedulePost})
      const {data} = await $post({ url: 'master/order/project',
        data: dataPost
      })
      localStorage.removeItem('order');
      localStorage.removeItem('scheduleOrder');
      router.push({name: 'apps-ordercust-created', params: {inv_id : data.inv_id}})
    }

    const setForm = ()=> {
      form.value = JSON.parse(localStorage.getItem('order'))
      inputedSchedule.value = JSON.parse(localStorage.getItem('scheduleOrder'))
      console.log(inputedSchedule.value)
    }
  
    onMounted(() => {
    getVendorconfig()
     setForm()
    })

 
 return{
   inputedSchedule,
   selectedSchedule,
   orderRules, configDropship,
   form,
   currentDetailOrder,
    formatCurrency,
    formatNumber,
    formatDateTimeToDate,
    formatDateTimeToTime,
   postForm
 }
  },
  methods: {
    customLabelConsignee ({ consignee_name, consignee_address, consignee_phone }) {
      return `${consignee_name} – ${consignee_address} – ${consignee_phone}`
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-3">
                {{inputedSchedule.sch_vendor.name}} - {{inputedSchedule.sch_flight_no}} <br/>
                Departure {{formatDateTimeToDate(inputedSchedule.departure_datetime)}}
              </div>
              <div class="col-xl-3 text-center">
                {{formatDateTimeToTime(inputedSchedule.departure_datetime)}} <i class="ri-arrow-right-line"></i> {{formatDateTimeToTime(inputedSchedule.arrival_datetime)}}<br/>
                {{inputedSchedule.origin_port.prt_name}} ({{inputedSchedule.origin_port.prt_initial}}) <i class="ri-arrow-right-line"></i> {{inputedSchedule.destination_port.prt_name}} ({{inputedSchedule.destination_port.prt_initial}})
              </div>
              <div class="col-xl-3 text-center">
                {{Math.floor(inputedSchedule.sch_duration/60)}}h {{Math.floor(inputedSchedule.sch_duration%60)}}m<br/>
                <div v-if="inputedSchedule.sch_number_of_transit > 0">{{inputedSchedule.sch_number_of_transit}} Transit</div>
                    <div v-else>Direct</div>
              </div>
              <div class="col-xl-3 text-center">
                <h5 class="mb-0">{{ formatCurrency(inputedSchedule.sch_tariff_per_kg) }}/kg</h5>
                <div>Available <span class="font-size-20 font-weight-bold">{{ formatNumber(inputedSchedule.sch_availablecapacity) }}kg</span></div>
              </div>               
            </div>
            <br/>
            <div class="row">
              <div class="col-12">
                Notes : Goods must be in <u class="font-weight-bold">{{configDropship.locationname}}</u> a 
                maximum of <u class="font-weight-bold">{{configDropship.limithour}} hours</u> before departure time
              </div>               
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                  <div class="table-responsive">
                    <table class="table font-size-13 table-bordered">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Goods Type</th>
                          <th>Remarks</th>
                          <th>Collies</th>
                          <th>Total Chargeable Weight</th>
                          <th>Total Freight Charges</th>
                          <th>Total Surcharge</th>
                          <th>Total Handling Heavy Charge</th>
                          <th>Total Charge</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(detailOrder,index) in form.details" :key="index">
                          <th scope="row">{{index+1}}</th>
                          <td>{{detailOrder.oddGoodsTypeDescr}}</td>
                          <td>{{detailOrder.oddRemarks}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddCollies)}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddChargeweightround*detailOrder.oddCollies)}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddFreightcharge*detailOrder.oddCollies)}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddSurchargeamount*detailOrder.oddCollies)}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddHandlingcharge*detailOrder.oddCollies)}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddTotalcharge)}}</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr class="font-weight-bold">
                          <td class="text-right" colspan="3">Total</td>
                          <td class="text-right">{{formatNumber(form.ordCollies)}}</td>
                          <td class="text-right">{{formatNumber(form.ordWeight)}}</td>
                          <td class="text-right">{{formatNumber(form.ordFreightcharge)}}</td>
                          <td class="text-right">{{formatNumber(form.ordSurcharge)}}</td>
                          <td class="text-right">{{formatNumber(form.ordHandlingcharge)}}</td>
                          <td class="text-right">{{formatNumber(form.ordFreightcharge+form.ordSurcharge+form.ordHandlingcharge)}}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div class="row">
                    <div class="offset-md-7 col-md-5">
                      <b-form-group
                        label-cols-sm="5"
                        label-cols-lg="5"
                        class="mb-0"
                        label="Handling DG Charge"
                        label-for=""
                        v-if="form.ordHandlingdgcharge > 0"
                      >
                        <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordHandlingdgcharge) }}</h6></div>
                      </b-form-group>
                      <hr class="m-0"/>
                      <b-form-group
                        label-cols-sm="5"
                        label-cols-lg="5"
                        class="mb-0"
                        label="Admin SMU"
                        label-for=""
                      >
                        <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordAdminfee) }}</h6></div>
                      </b-form-group>
                      <hr class="m-0"/>
                      <b-form-group
                        label-cols-sm="5"
                        label-cols-lg="5"
                        class="mb-0"
                        label="Admin Fee"
                        label-for=""
                      >
                        <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordVatcharge) }}</h6></div>
                      </b-form-group>
                      <hr class="m-0"/>
                      <b-form-group
                        label-cols-sm="5"
                        label-cols-lg="5"
                        class="mb-0"
                        label="Grand Total"
                        label-for=""
                      >
                        <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordGtotalcharge) }}</h6></div>
                      </b-form-group>
                      <hr class="m-0"/>
                    </div>
                  </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                    <h5 class="font-size-14">Payment method :</h5>
                    <div class="row">
                      <div class="col-lg-4 col-sm-6">
                        <div>
                          <label class="card-radio-label mb-3">
                            <input
                              type="radio"
                              name="pay-method"
                              id="pay-methodoption1"
                              class="card-radio-input"
                              checked
                            />

                            <div class="card-radio">
                              <span>Bank Transfer</span>
                            </div>
                          </label>
                        </div>
                      </div>

                      <div class="col-lg-4 col-sm-6">
                        <div>
                          <label class="card-radio-label mb-0">
                            <input
                              type="radio"
                              name="pay-method"
                              id="pay-methodoption2"
                              class="card-radio-input"
                              disabled
                            />
                            <div class="card-radio">
                              <span>Transfer Virtual Account</span>
                            </div>
                          </label>
                          <i>*Comming Soon</i>
                        </div>
                      </div>

                      <div class="col-lg-4 col-sm-6">
                        <div>
                          <label class="card-radio-label mb-0">
                            <input
                              type="radio"
                              name="pay-method"
                              id="pay-methodoption3"
                              class="card-radio-input"
                              disabled
                            />
                            <div class="card-radio">
                              <span>Pay Later - Comming Soon</span>
                            </div>
                          </label>
                          <i>*Comming Soon</i>
                        </div>
                      </div>
                    </div>
                <hr/>
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input id="invalidCheck" type="checkbox" class="custom-control-input" v-model="checkedtnc" />
                    <label
                      class="custom-control-label"
                      for="invalidCheck"
                    >Agree to terms and conditions</label>
                    <div class="invalid-feedback">You must agree before submitting.</div>
                  </div>
                  Show terms and conditions
                </div>

                <div class="button-items">
                  <b-button variant="primary" @click="postForm" :disabled="!checkedtnc">
                    <i class="ri-save-line align-middle"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger" :to="{ name: 'apps-order-createinput'}">
                    <i class="ri-arrow-left-line align-middle"></i> Back
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>